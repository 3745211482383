.customButton {
  box-shadow: "0px 0px 0px 0px rgb(0 0 0 / 6%), 0 0px 29px 0 rgb(0 0 0 / 32%)";
  border-color: "#CFADAC";
}
.customButton:hover {
  background-color: rgba(207, 173, 172, 0.606);
}

@media only screen and (max-width: 600px) {
  .customButton {
    height: 45px !important;
    font-size: 0.925rem !important;
    max-width: 165px;
  }
}
