.slick-slide > div {
  display: grid;
  place-items: center;
  width: 80%;
  margin-top: 50px;
  margin: auto;
  height: 500px;
  padding: 0px;
}

.ellipse3 {
  box-sizing: border-box;

  opacity: 0.1;
  /* Dark Copy */
  border: 1px solid #23242c;
  border-radius: 360px;

  position: absolute;
  width: 518px;
  height: 518px;
  left: 88px;

  background: #ffffff;
  z-index: -2;
}
.ellipse4 {
  box-sizing: border-box;
  border: 1px solid #fae2d666;
  border-radius: 360px;
  width: 341px;
  height: 341px;
  left: 170px;
  top: 83px;
  position: absolute;

  background: #ffffff;
  box-shadow: 0px 0px 64px #cca89a;
  z-index: -2;
  /* background: rgba(250, 226, 214, 0.4); */
}
@media screen and (max-width: 450px) {
  .ellipse3 {
    left: -16%;
    width: 0px;
    height: 0px;
  }
  .ellipse4 {
    left: 7%;
  }
}
