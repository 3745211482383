.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 33px;
  right: 40px;
  background-color: #cca89a;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
hr {
  border-color: #aaa;
  box-sizing: border-box;
  width: 100%;
}
button::focus {
  border: 0;
  color: transparent;
}
a::focus {
  border: 0;
  color: transparent;
}

button::after {
  border: 0;
  color: transparent;
}
a::after {
  border: 0;
  color: transparent;
}
@media screen and (max-width: 720px) {
  .float {
    right: 15px;
  }
}
/* Underline styles */
.text-decoration {
  display: block;
  position: relative;
  padding: 0.2em 0;
}

/* Fade in */
.text-decoration::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #cca89a;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.text-decoration:hover::after,
.text-decoration:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* Scale from center */
.text-decoration::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.text-decoration:hover::after,
.text-decoration:focus::after {
  transform: scale(1);
}
